import React, { useState, useCallback } from "react";
import styles from './Error.module.css';
import axios from "../handle/axiosConfig";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { useLoaderData } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { renderNavbar } from "../redux/slices/NavbarSlice";
export const fetchErrorItem = async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/admin/error/get-all`);
    return res.data;
};

const ModelDelete = ({ codeDelete, handleChangeInput, setIsShowModel, handleDeleteItem }) => {
    return (
        <div className={styles.model}>
            <div>
                <button onClick={() => setIsShowModel(false)}><IoClose size={25} /></button>
                <div>
                    <input type="text" value={codeDelete} onChange={handleChangeInput} />
                    <button onClick={handleDeleteItem}>Delete</button>
                </div>
            </div>
        </div>
    );
};

const Item = ({ info, setIsShowModel , setValueDelete }) => {
    return (
        <div className={styles.item}>
            <p>{info.id}</p>
            <p>{info.content}</p>
            <p>{info.where.controllers}</p>
            <p>{info.where.function}</p>
            <p>{info.createdAt}</p>
            <p><button onClick={() => {setIsShowModel(true); setValueDelete(info.id)}}><RiDeleteBin6Line /></button></p>
        </div>
    );
};

const Error = () => {
    const initialData = useLoaderData();
    const dispatch = useDispatch();
    const [data, setData] = useState(initialData);
    const [isShowModel, setIsShowModel] = useState(false);
    const [codeDelete, setCodeDelete] = useState('');
    const [valueDatele , setValueDelete] = useState('');

    const handleDeleteItem = async () => {
        if(codeDelete === process.env.REACT_APP_CODE_DELETE_ERROR){
            try{
                await axios.get(`${process.env.REACT_APP_URL_BACKEND}/admin/error/delete?id=${valueDatele}`);
                setIsShowModel(false);
                setData(data.filter(item => item.id !== valueDatele));
                setCodeDelete('');
                setValueDelete('');
                dispatch(renderNavbar());
            }catch (error){
                toast.error('Dekete Fail!!');
            }
        }
    };

    const handleChangeInput = useCallback((e) => {
        setCodeDelete(e.target.value);
    }, []);

    return (
        <div className={styles.layout}>
             <ToastContainer
                position={'top-right'}
                closeOnClick={true}
                autoClose = {5000}
                draggable= {true}
            />
            {isShowModel && (
                <ModelDelete
                    codeDelete={codeDelete}
                    handleChangeInput={handleChangeInput}
                    setIsShowModel={setIsShowModel}
                    handleDeleteItem={handleDeleteItem}
                />
            )}
            <div className={styles.header}>
                <p>Index</p>
                <p>Content.Err</p>
                <p>.js</p>
                <p>.Function</p>
                <p>CreateDate</p>
                <p>x</p>
            </div>
            {data.map((item, index) => (
                <Item key={`${index}-item-error`} info={item} setIsShowModel={setIsShowModel} setValueDelete={setValueDelete} />
            ))}
        </div>
    );
}

export default Error;
