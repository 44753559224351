import React, {useEffect , useState ,memo} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './ProductItem.module.css';
import { FiDollarSign } from "react-icons/fi";
import { MdCheck } from "react-icons/md";
import Price from './Price';

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}



const ProductItem = ({ type, item }) => {
    const navigate = useNavigate();
    const PORT = process.env.REACT_APP_URL_BACKEND;
    const [checkImg, setCheckImg] = useState({});
    const keyImg = [
      'main.jpg',
      'hover.jpg',
      'extra-1.jpg',
      'size-guide.jpg',
    ];
  
    useEffect(() => {
        if(item){
              function checkImageExists(url) {
                return new Promise((resolve) => {
                  const img = new Image();
                  img.onload = () => resolve(true);
                  img.onerror = () => resolve(false);
                  img.src = url;
                });
              }
          
              const checkImages = async () => {
                const results = {};
                for (const key of keyImg) {
                  const url = `${PORT}/images/${item.url}/${key}`;
                  results[key] = await checkImageExists(url);
                }
                setCheckImg(results);
              };
          
              checkImages();
        }
            
    }, []);

    const handleDoubleClick = ()=>{
        navigate(`/products/edit/${item.url}`);
    }

    return (
        type === 'header'?   
            <div className={`${styles.container} ${styles.header}`}>
                <div>
                    <p>Type</p>
                </div>
                <div>
                    <p>Name</p>
                </div>
               
                <div>
                    <p>Status</p>
                </div>
                <div>
                    <p>Price</p>
                </div>
                <div>
                    <p>S</p>
                    <p>M</p>
                    <p>L</p>
                </div>
             
                <div>
                    <p>Update time</p>
                </div>
                <div>
                    <p>Main</p>
                    <p>Hover</p>
                    <p>Size Guide</p>
                    <p>Extra</p>
                </div>
                <div>
                    <p>Img</p>
                </div>
            </div>
            :
            <div 
                className={styles.container}
                onDoubleClick={() =>handleDoubleClick(item.url)}
            >
                <div>
                    <p>{item.type}</p>
                    <p>/{item.url}</p>
                </div>
                <div>
                    <p>{item.name}</p>
                </div>
                <div>
                    <p>{item.status}</p>
                </div>
                <div>
                    <p><Price price={item.price_vi} language={'vi'}/><span>| vi</span> </p>
                    <p><FiDollarSign size={13} /><Price price={item.price_en} language={'en'}/><span>| en</span></p>
                </div>
                <div>
                    {console.log(typeof item.sizeOption.size_S)}
                    <p className={Number(item.sizeOption.size_S) === 0 ? styles.nullSize : undefined}>{item.sizeOption.size_S}</p>
                    <p className={Number(item.sizeOption.size_M) === 0 ? styles.nullSize : undefined}>{item.sizeOption.size_M}</p>
                    <p className={Number(item.sizeOption.size_L)=== 0 ? styles.nullSize : undefined}>{item.sizeOption.size_L}</p>
                </div>
                <div>
                    <p>{formatDate(item.updatedAt)}</p>
                </div>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>{checkImg['main.jpg'] ? <MdCheck /> : 'No' }</td>
                                <td>{checkImg['hover.jpg'] ? <MdCheck />  : 'No' }</td>
                                <td>{checkImg['size-guide.jpg'] ? <MdCheck />  : 'No' }</td>
                                <td>{checkImg['extra-1.jpg'] ? <MdCheck />  : 'No' } <span>[{item.imgExtraLength}]</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <img src={`${PORT}/images/${item.url}/main.jpg`} alt={'none'} 
                        onError={(e) => {
                            e.target.src = require("../assets/image-slash-1.png");
                        }}  
                    />
                    <img src={`${PORT}/images/${item.url}/hover.jpg`} alt={'none'} 
                        onError={(e) => {
                            e.target.src = require("../assets/image-slash-1.png");
                        }} 
                    />
                    <img src={`${PORT}/images/${item.url}/size-guide.jpg`} alt={'none'}
                        onError={(e) => {
                            e.target.src = require("../assets/image-slash-1.png");
                        }} 
                    />
                    {
                        item.imgExtraLength !== 0 &&
                        Array.from({ length: item.imgExtraLength }).map((_, i) => (
                            <img key={`extra-${i + 1}`} src={`${PORT}/images/${item.url}/extra-${i + 1}.jpg`} alt="none" 
                                onError={(e) => {
                                    e.target.src = require("../assets/image-slash-1.png");
                                }} 
                            />
                        ))
                    }
                </div>
            </div>
    )
}




export default memo(ProductItem)