import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import MainPage from './pages/MainPage';
import Oders from './pages/Oders';
import Products from './pages/Products';
import EditItem from './pages/EditItem';
import OdersItem , { fetchOdersItem}  from './components/OdersItem';
import Discount from './pages/Discount';
import Error , { fetchErrorItem} from './pages/Error';
import Newsletter from './pages/Newsletter';

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    children: [
        {
          path:'/oders',
          element: <Oders/>, 
          children: [
            {
              path: ':id',
              element: <OdersItem />,
              loader: fetchOdersItem
            }
          ]
        },
        {
          path: 'products',
          element: <Products/>
        },
        {
          path: 'products/add',
          element: <EditItem />, 
        },
        {
          path: 'products/edit/:id',
          element: <EditItem />, 
        },
        {
          path: 'discount',
          element: <Discount/>
        },
        {
          path: 'error',
          element: <Error/>,
          loader: fetchErrorItem
        },
        {
          path: 'newsletter',
          element: <Newsletter/>
        }
       
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
          <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
