import React , {useState} from "react";
import styles from './Login.module.css';
import { BiShow } from "react-icons/bi";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


const Login  =()=>{
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [accountValue, setAccountValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [codeValue, setCodeValue] = useState('');

    const onLogin = async ()=>{
        if(accountValue.length !==0 && passwordValue.length !==0 &&  codeValue.length !==0 ){
            try{
                const request = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/admin/login` , {
                    account: accountValue,
                    password: passwordValue,
                    code: codeValue
                })
                sessionStorage.setItem('token', request.data.token);
                window.location.reload();

            } catch (err) {
                if (err.response && err.response.status === 401) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error('An error occurred. Please try again.');
                }
            }
        }
    }

    return(
        <>
            <ToastContainer 
                    position={'top-right'}
                    closeOnClick={true}
                    autoClose = {3000}
                    draggable= {true}
            />
            <div className={styles.layout}>
                <div>
                    <div className={styles.logo}>
                        <p>Terror.Admin</p>
                    </div>
                    <input type="text" placeholder="Account"  value={accountValue} onChange={(e)=> setAccountValue(e.target.value)}/>
                    <div>
                        <input type={isShowPassword ? "text" : "password"} placeholder="Password"  value={passwordValue} onChange={(e)=> setPasswordValue(e.target.value)}/>
                        <button onClick={()=> setIsShowPassword(!isShowPassword)}><BiShow /></button>
                    </div>
                    <input type="text" placeholder="Code" value={codeValue} onChange={(e)=> setCodeValue(e.target.value)}/>
                    <button onClick={onLogin}>Login</button>
                </div>
            </div>
        </>
    )
}

export default Login;