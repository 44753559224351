import React , {useState,useEffect} from "react";
import styles from './Newsletter.module.css';
import axios from "../handle/axiosConfig";


const Newsletter = () =>{
    const [data, setData] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const getData = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/admin/newsletter/get-all`);
            setData(getData.data);
        }
        fetchData();
    }, [] )

    function formatDate(isoString) {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    return(
        <div className={styles.layout}>
            <div className={styles.frame_1}>
                <div>
                    <p>Email</p>
                    <p>Create Date</p>
                </div>
                <ul>
                    {
                        data.map((item,index) => (
                            <li key={`newsletter-item-${index}`}>{item.email} <span>{formatDate(item.createdAt)}</span></li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Newsletter