import React from "react";
import styles from './Price.module.css';

const Price = ({price , language }) => {
    const formattedPrice = language  === 'vi'  ? (Number(price) * 1000).toLocaleString('en-US') : price;
    return (
        <span className={styles.layout}>
            {formattedPrice}
            {language === 'vi' && (<><span>đ</span></>)}
        </span>
    );
}

export default Price;