export function checkNull ( value , checkID   ){
    let err = [];


    if(value.name.trim().length === 0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (name)'})
    }
    if(value.url.trim().length === 0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (url)'})
    }
    if(checkID.name.includes(value.name.trim())){
        err.push({status: 'error' ,label: 'Name đã tồn tại.'})
    }
    if(checkID.url.includes(value.url.trim())){
        err.push({status: 'error' ,label: 'Url đã tồn tại.'})
    }
    if(!value.price_en ||  value.price_en === 0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (price_en)'})
    }
    if(!value.price_vi || value.price_vi === 0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (price_vi)'})
    }
    if(value.description_list_vi.length === 0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (description for vietnam)'})
    }
    if(value.description_list_en.length === 0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (description for english)'})
    }
    if(!value.color || value.color.trim().length === 0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (color)'})
    }
    if(value.status === 'Promotion'  && value.salePrice_percent ===0){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (% Sale)'})
    }
    if(value.status === 'Promotion' && value.salePrice_vi === 0 ){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (Price for Vietnam)'})
    }
    if(value.status === 'Promotion' && value.salePrice_en === 0 ){
        err.push({status: 'error' ,label: 'Thiếu dữ liệu (Price for English)'})
    }
    
    return err
}

