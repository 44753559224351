import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isRender: true
};

const navbarSlice = createSlice({
    name: 'navbarSlice',
    initialState,
    reducers: {
        renderNavbar: (state) => {
            state.isRender = true; 
        },
        finishNavbar: (state) => {
            state.isRender = false; 
        }
    }
});

export const { renderNavbar, finishNavbar  } = navbarSlice.actions;
export default navbarSlice.reducer;
