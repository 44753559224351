import React , {useState,useEffect} from 'react';
import { useNavigate , Outlet, useParams } from 'react-router-dom';
import axios from "../handle/axiosConfig";

import styles from './Oders.module.css';
import { IoSearch } from "react-icons/io5";
import { DiDotnet } from "react-icons/di";
import { useSelector, useDispatch } from "react-redux";
import { finishRenderStatus } from '../redux/slices/OrdersSlice';
import { MdOutlineDateRange } from "react-icons/md";


function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear().toString().slice(-2); 
    return `${day}-${month}-${year}`;
}

const Item = ({ value, isOpenDate }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleClick = () => {
        if (id === value.orderID) {
            navigate('/oders/');
        } else {
            navigate(`/oders/${value.orderID}`);
        }
    };

    return (
        <button
            onClick={handleClick}
            className={`${styles.item} ${value.status === 'done' ? styles.finish : styles.handle} ${
                id === value.orderID ? styles.nativeLink : ''
            }`}
        >
            <p>{value.status === 'processing' ? <DiDotnet /> : null}</p>
            <p>{value.orderID}</p>
            <p>{value.location}</p>
            {
                isOpenDate &&
                <p>{formatDate(value.createdAt)}</p>
            }

        </button>
    );
};

const Oders = () =>{
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [search , setSearch] = useState('');
    const isRendering = useSelector(state => state.ordersSlice.isRender);
    const [isOpenDate , setIsOpenDate] = useState(false);

    const fectchData = async ()=>{
        const fetch = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/admin/orders/all`);
        dispatch(finishRenderStatus())
        setData(fetch.data);
    }

    useEffect(()=>{
        if(isRendering){
            fectchData();
        }
    },[ isRendering, dispatch]);

    useEffect(()=>{
        fectchData()
    },[]);

    return (
        <div className={styles.layout}>
            <div className={styles.list}>
                <div className={styles.label}>
                    <p>// = render new orders</p>
                    <button onClick={()=> setIsOpenDate(!isOpenDate)}><MdOutlineDateRange size={17} /></button>
                </div>
                <div className={styles.search}>
                    <IoSearch />
                    <input value={search} onChange={e => setSearch(e.target.value)} type='text'/>
                </div>
                <div className={styles.id}>
                    {
                        search.length > 0 ? 
                        search === '//' ? 
                        data
                        .filter(item => item.status !== 'done')
                        .map(item=>{
                            return <Item  key={item.orderID} value={item} isOpenDate={isOpenDate} />
                        })
                        :
                        data
                        .filter(item => item.orderID.toLowerCase().includes(search.toLowerCase()))
                        .map(item=>{
                            return <Item  key={item.orderID} value={item} isOpenDate={isOpenDate}/>
                        })
                        :
                        data.map(item=>{
                            return <Item  key={item.orderID} value={item} isOpenDate={isOpenDate}/>
                        })
                    }
                </div>
            </div>
            <div className={styles.detail}>
                <Outlet />
            </div>
        </div>
    )
}

export default Oders