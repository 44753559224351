import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isRender: true,
    countNew: 0
};

const ordersSlice = createSlice({
    name: 'ordersSlice',
    initialState,
    reducers: {
        renderStatus: (state) => {
            state.isRender = true; 
        },
        finishRenderStatus: (state) => {
            state.isRender = false; 
        }
    }
});

export const { renderStatus, finishRenderStatus  } = ordersSlice.actions;
export default ordersSlice.reducer;
