import React, { useEffect, useState } from "react";
import styles from './OdersItem.module.css';
import axios from '../handle/axiosConfig';
import { useLoaderData } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import {  useDispatch } from "react-redux";
import { renderStatus } from "../redux/slices/OrdersSlice";
import { renderNavbar } from '../redux/slices/NavbarSlice';
import Price from "./Price";




export const fetchOdersItem =  async ({params}) => {
    const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/admin/orders/get-item`, {
        params: params
    });
    return res.data;
};

const CartItem = ({value})=>{
    return(
        <div className={styles.cartItem}>
            <img src={`${process.env.REACT_APP_URL_BACKEND}/images/${value.url}/main.jpg`} alt={`${value.url} Cart | Terror`} />
            <div>
                <p>{value.name}</p>
                <p>{value.price}</p>
            </div>
            <p>Number:{value.number}</p>
            <p>Size: {value.size}</p>
        </div>
    )
}


const OdersItem =  () =>{
    const dispatch = useDispatch();
    const data =  useLoaderData();
    const info =  data.info;
    const cart =  data.cart;
    const [statusMode, setStatusMode] = useState(data.status);
    const date = new Date(data.updatedAt);
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear(); 
    const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

    const handleClick = async  (mode)=>{
        const changeValue = {
            id: data.orderID,
            newStatus: mode
        }
        setStatusMode(mode)
        await axios.post(`${process.env.REACT_APP_URL_BACKEND}/admin/orders/change-status`, changeValue );
        dispatch(renderStatus());
        dispatch(renderNavbar());
    }
    useEffect(()=>{
        setStatusMode(data.status);
    }, [data]);
    return(
        <div className={styles.layout}>
            <div className={styles.headerFrame}>
                <select onChange={(e) => handleClick(e.target.value)} value={statusMode}>
                    <option value="new_order">New Order</option>
                    <option value="processing">Processing</option>
                    <option value="done">Done</option>
                </select>
                <p><FaRegCalendarAlt size={13}/> {formattedDate}</p>
            </div>
            <div className={styles.infoFrame}>
                <h3>Info Customise</h3>
                <p><span>Full Name</span>{info.fullName}</p>
                <div>
                    <p><span>Phone</span>{info.phone}</p>
                    <p><span>Mail</span>{info.email}</p>
                </div>
                <p><span>Address</span>{info.address}</p>
                <div>
                    <p><span>Country</span>{info.country}</p>
                    <p><span>Province</span>{info.province}</p>
                    <p><span>District</span>{info.district}</p>
                </div>
                {
                    data.location == 'en' &&
                    <p><span>Postal Code</span>{info.postalCode}</p>
                }
                {
                    info.note === "none" || info.note.length === 0 ?   null : <p><span>Note</span>{info.note}</p>
                }
            </div>
            <div className={styles.cartFrame}>
                {cart.map((item,index) => <CartItem key={`${item.url}_${index}`} value={item} />)}
                    
                {
                    data.discount_code.codeName.length !== 0 && 
                    <p className={styles.useDiscountCode}>
                        <span>Use Discount Code:</span> {data.discount_code.codeName}
                        <span>Percent:</span> {data.discount_code.percent}%
                    </p>
                }
                <div className={styles.calcCartFrame}>
                    <div>
                        <p>Payment Method: <span>{data.payment}</span></p>
                        <p>Subtotal: <Price price={data.raw_price} language={data.location}/></p>
                        <p>Discount: <Price price={data.discount_price} language={data.location}/></p>
                        <p>Ship: <Price price={data.ship_price} language={data.location}/></p>
                        <p>Total: <Price price={data.raw_price - data.discount_price + data.ship_price} language={data.location}/> </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OdersItem;