import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Navbar.module.css';
import axios from '../handle/axiosConfig';
import { useSelector , useDispatch } from 'react-redux';
import { finishNavbar } from '../redux/slices/NavbarSlice';





const Navbar = () =>{
   
    const isRendering = useSelector(state => state.navbarSlice.isRender);
    const [modelLogout, setModelLogout] = useState(false);
    const dispatch = useDispatch();

    const [data, setdata] = useState({
        order: 0,
        product: 0,
        newsletter: 0,
        discount: 0
    });

    useEffect(()=>{
        async function fetchIndicator(){
            if(isRendering ){
                const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/admin/indicator`);
                setdata(res.data);
                dispatch(finishNavbar());
            }
        }
        fetchIndicator();
    },[isRendering, dispatch]);

    const ModelLogout = ()=>{
        return(
            <div className={styles.layout_model}>
                <div>
                    <p>Logout?</p>
                    <div>
                        <button onClick={()=> setModelLogout(false)}>Cancel</button>
                        <button onClick={()=>{
                            sessionStorage.removeItem('token');
                            window.location.reload();
                        }}>Yup!</button>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className={styles.container}>
                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'oders'}><span>Orders</span> <span>&#91;{data.order}&#93;</span></NavLink>
                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'products'}><span>Products</span> <span>&#91;{data.product}&#93;</span></NavLink>
                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'discount'}><span>Discount</span> <span>&#91;{data.discount}&#93;</span></NavLink> 

                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'newsletter'}><span>Newsletter</span> <span>&#91;{data.newsletter}&#93;</span></NavLink> 
                <NavLink className={({isActive})=>(isActive? styles.activeLink : styles.noneActiveLink)} to={'error'}><span>Error</span> <span>&#91;{data.error}&#93;</span></NavLink> 

                <button className={styles.logout} onClick={()=> setModelLogout(true)}>Logout</button>
            </div>
            {modelLogout && <ModelLogout />}
        </>
    )
}

export default Navbar