import React , {useEffect , useState} from 'react';
import { Outlet} from 'react-router-dom';
import NavBar from '../components/Navbar';
import styles from './MainPage.module.css'
import Login from './Login';

const MainPage = () =>{
    const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('token'));

   
    return (
        isAuthenticated ? 
            <div className={styles.container}>
                <div className={styles.navbar}>
                    <NavBar  />
                </div>
                <div className={styles.frame}>
                    <Outlet />
                </div>
            </div>
         : 
        <Login setIsAuthenticated={setIsAuthenticated} />
    );
}

export default MainPage