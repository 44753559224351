import React, { useEffect, useState    } from 'react';
import { useNavigate, useParams , useLocation  } from 'react-router-dom';
import styles from './EditItem.module.css';
import axios from "../handle/axiosConfig";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { renderNavbar } from '../redux/slices/NavbarSlice';
import { useDispatch } from 'react-redux';
import { IoCloseSharp } from "react-icons/io5";
import { List, arrayMove } from 'react-movable';
import { checkNull } from '../handle/Edititem.Check';
import { ReactComponent as Spinner } from '../assets/spinner.svg';


const EditItem = () => {
    const PORT = process.env.REACT_APP_URL_BACKEND;
    const [loading, setLoading] = useState(false);
    const location  = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [checkID , setcheckID] = useState({
        name: [],
        url: []
    });
    const [idEdit, setIdEdit] = useState ({
        url: '',
        name: ''
    });
    
    const mode = location.pathname.includes('add') ? 'add' : 'edit';
    const [isModelDelete , setIsModelDelete] = useState(false);
    const [isModelEdit , setIsModelEdit ] = useState(false);
    const [countEsc , setCountEsc] = useState(0);
    const [isSaveBtn, setIsSaveBtn] = useState(false);

    const [addDescription_vi , setAddDescription_vi] = useState('');
    const [addDescription_en , setAddDescription_en] = useState('');

    const [colorSuggest , setColorSuggest] = useState([]);

    const [addRelatedValue , setAddRelatedValue] = useState({
        name: '',
        url: ''
    });
    const [addRelatedValue_suggest , setAddRelatedValue_suggest] = useState([]);

    const [value , setValue] = useState({
        name: '',
        url: '',
        price_vi: 0,
        price_en: 0,
        status: 'No',
        type: 'tops',
        salePrice_vi: 0,
        salePrice_en: 0,
        salePrice_percent: 0,
        size_S: 1,
        size_M: 1,
        size_L : 1,
        description_list_vi: [],
        description_list_en: [],
        color: '',
        related: [],
        imgExtraLength: 0
    });

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setCountEsc(countEsc + 1)
            }
        };
        document.addEventListener('keydown', handleKeyPress);

        if(countEsc > 3){
            navigate('/products');
            return () => {
            document.removeEventListener('keydown', handleKeyPress);
            };
        }
    }, [countEsc, navigate ]);

    useEffect(()=>{
        setIsSaveBtn(false)
    },[value]);

    useEffect(() => {
        const calc_en_percent = Math.round( Number(value.price_en) * Number(value.salePrice_percent) / 100);
        const calc_vi_percent =  Math.round(Number(value.price_vi) * Number(value.salePrice_percent) / 100);
 
        const calc_en = Number(value.price_en) - calc_en_percent;
        const calc_vi = Number(value.price_vi) - calc_vi_percent;
 
         setValue(prevState => ({ ...prevState, salePrice_vi: calc_vi }))
         setValue(prevState => ({ ...prevState, salePrice_en: calc_en }))
         
     }, [value.price_en, value.price_vi, value.salePrice_percent]);

    useEffect(()=>{
        async function getData (){
            const response  = await axios.get(`${PORT}/admin/products/get-id`);
            setcheckID({
                name: response.data.name,
                url: response.data.url,
            })
            setColorSuggest(response.data.color);
            if(mode === 'edit'){
                const responseItem = await axios.get(`${PORT}/admin/products/edit?id=${id}`);
                const edit = responseItem.data;
                setValue({
                    name: edit.name,
                    url: edit.url,
                    price_vi: edit.price_vi,
                    price_en: edit.price_en,
                    status: edit.status,
                    type: edit.type,
                    salePrice_vi: edit.salePrice_vi,
                    salePrice_en: edit.salePrice_en,
                    salePrice_percent: edit.salePrice_percent,
                    size_S: edit.sizeOption.size_S,
                    size_M: edit.sizeOption.size_M,
                    size_L : edit.sizeOption.size_L,
                    description_list_vi: edit.description_list_vi,
                    description_list_en: edit.description_list_en,
                    color:  edit.color,
                    related: edit.related,
                    imgExtraLength: edit.imgExtraLength
                });
                setcheckID({
                    name: checkID.name.filter(item => item !== edit.name ),
                    url: checkID.url.filter(item => item !== edit.url ),
                })
                setIdEdit({
                    url: edit.url,
                    name: edit.name,
                })
            }
        };
        getData();
    },[]);

    const onBlurNumber = (field) => {
        if (value[field] <= 0) {
            setValue(prevState => ({ ...prevState, [field]: '0' }));
        }
    };
    const onChangeName = (e)=>{
        setValue(prevState => ({ ...prevState, name: e.target.value }))
        const convertToUrl = e.target.value.toLowerCase().replace(/\s+/g, '-');
        setValue(prevState => ({ ...prevState, url: convertToUrl }));
    }
    const onChangeUrl = (e)=>{
        setValue(prevState => ({ ...prevState, url: e.target.value })); 
    }

    const addDescriptionValue = (language) => {
        if (language === 'vi' && addDescription_vi) {
            setValue(prevState => ({
                ...prevState,
                description_list_vi: [...prevState.description_list_vi, addDescription_vi]
            }));
            setAddDescription_vi('');
        } else if (language === 'en' && addDescription_en) {
            setValue(prevState => ({
                ...prevState,
                description_list_en: [...prevState.description_list_en, addDescription_en]
            }));
            setAddDescription_en('');
        }
    };
   
    const removeDescriptionItem = (language, text) => {
        if (language === 'vi') {
            const newValue = value.description_list_vi.filter(item => item !== text);
            setValue(prevState => ({ ...prevState, description_list_vi: newValue }));
        } else {
            const newValue = value.description_list_en.filter(item => item !== text);
            setValue(prevState => ({ ...prevState, description_list_en: newValue }));
        }
    };

    const addRelatedItem = () => {
        if (addRelatedValue.name.length !== 0 && addRelatedValue.url.length !== 0) {
            setValue(prevState => ({ ...prevState, related: [...value.related ,  { name: addRelatedValue.name, url: addRelatedValue.url }] }));
            setAddRelatedValue({ name: '', url: '' });
        }
    };

    const removeRelatedItem = (text)=>{
        const newValue = value.related.filter(item => item !== text);
        setValue(prevState => ({ ...prevState, related: newValue }));
    };

    const handleRelatedFocus = async ()=>{
        const getItem = await axios.get(`${PORT}/admin/get-item-related?type=${value.type}`);
        setAddRelatedValue_suggest(getItem.data);
    }
   

    const deleteItem = async ()=>{
        await axios.delete(`${PORT}/admin/products/delete?id=${idEdit.url}`);
        dispatch(renderNavbar());
        navigate('/products');
    }

    const handleCheck = async ()=>{
        const err = await checkNull(value , checkID);
        err.forEach( item => {
            if(item.status === "error") toast.error(item.label);
            if(item.status === "warning") toast.warning(item.label);
        });
        const hasError = err.some(error => error.status === 'error');
        if(!hasError){
            setIsSaveBtn(true);
        }
      
    }
    
    const handleSave = async ()=>{
        if( mode === "add"){
            await axios.post(`${PORT}/admin/products/add`, value);
            dispatch(renderNavbar());
            navigate('/products');
        }
        if( mode === 'edit'){
            setIsModelEdit(true);
            await axios.post(`${PORT}/admin/products/edit`, {id: idEdit.url , newData: value});
            dispatch(renderNavbar());
            navigate('/products');
        }

    }
   
    const ModelDelete = ()=>{
        return(
            <div className={styles.layout_model_delete}>
                {
                loading?
                <Spinner />
                :
                <div>
                    <p>Delete [ {value.name} ] ?</p>
                    <div>
                        <button onClick={()=> setIsModelDelete(false)}>Cancel</button>
                        <button onClick={()=>{deleteItem(); setLoading(true);}}>Yes..</button>
                    </div>
                </div>
                }
            </div>
        )
    }
    const ModelEdit = ()=>{
            return(
                <div className={styles.layout_model_edit}>
                    <Spinner />
                </div>
            )
    }

    

    return(
        <>
            <ToastContainer
                position={'top-right'}
                closeOnClick={true}
                autoClose = {7000}
                draggable= {true}
            />
            <div className={styles.layout}>
                <p className={styles.closeBtn}>Press {'(ESC)'} x4 times for exit</p>
                <div className={styles.form}>
                    <section>
                        <div>
                            <div>
                                <label>Name*</label>
                                <input type='text' value={value.name} onChange={(e) => onChangeName(e)}/>
                            </div>
                            <div>
                                <label>Url*</label>
                                <input type='text' value={value.url}  onChange={(e)=> onChangeUrl(e)}  />
                            </div>
                        </div>
                    </section>
                    <section>
                        <label>Price*</label>
                        <div>
                            <div>
                                <label>english</label>
                                <input type='number' value={value.price_en}  onBlur={()=> onBlurNumber('price_en')}  onChange={(e)=>setValue(prevState => ({ ...prevState,price_en: e.target.value }))}  />
                            </div>
                            <div>
                                <label>vi</label>
                                <input type='number' value={value.price_vi}  onBlur={()=> onBlurNumber('price_vi')}  onChange={(e)=>setValue(prevState => ({ ...prevState, price_vi: e.target.value }))}  />
                            </div>
                        </div>
                    </section>
                    <section>
                    <div className={styles.optionFrame}>
                        <div>
                            <label>Status*</label>
                                <select onChange={(e)=> setValue(prevState => ({ ...prevState,status: e.target.value}))} value={value.status}>
                                    <option >Not_Show</option>
                                    <option >No</option>
                                    <option >New</option>
                                    <option >Best</option>
                                    <option >Promotion</option>
                                </select>
                            </div>
                            <div>
                                <label>Type*</label>
                                <select 
                                    onChange={(e)=> setValue(prevState => ({ ...prevState, type: e.target.value , related: []}))} 
                                    value={value.type}
                                >
                                    <option >tops</option>
                                    <option >bottoms</option>
                                    <option >outerwears</option>
                                    <option >accessories</option>
                                </select>
                            </div>
                        </div>
                        {
                                value.status !== "Promotion"? null :
                                <div>
                                    <div>
                                        <label>% Sale</label>
                                        <input type='number' value={value.salePrice_percent}  onBlur={()=> onBlurNumber('salePrice_percent')} onChange={(e)=>setValue(prevState => ({ ...prevState, salePrice_percent: e.target.value }))}  />
                                    </div>
                                    <div>
                                        <label>Price for English</label>
                                        <input type='number' value={value.salePrice_en} onBlur={()=> onBlurNumber('salePrice_en')} onChange={(e)=>setValue(prevState => ({ ...prevState, salePrice_en: e.target.value }))}  />
                                    </div>
                                    <div>
                                        <label>Price for Vietnam</label>
                                        <input type='number' value={value.salePrice_vi} onBlur={()=> onBlurNumber('salePrice_vi')} onChange={(e)=>setValue(prevState => ({ ...prevState, salePrice_vi: e.target.value }))}  />
                                    </div>
                                </div>

                        }
                    </section>
                    <section>
                        <div>
                            <div>
                                <label>Size S</label>
                                <input type='number' value={value.size_S} min={0} onBlur={()=> onBlurNumber('size_S')} onChange={(e)=>setValue(prevState => ({ ...prevState,size_S: e.target.value }))}  />
                            </div>
                            <div>
                                <label>Size M</label>
                                <input type='number' value={value.size_M} min={0} onBlur={()=> onBlurNumber('size_M')} onChange={(e)=>setValue(prevState => ({ ...prevState,size_M: e.target.value }))}  />
                            </div>
                            <div>
                                <label>Size L</label>
                                <input type='number' value={value.size_L} min={0} onBlur={()=> onBlurNumber('size_L')}  onChange={(e)=>setValue(prevState => ({ ...prevState,size_L: e.target.value }))}  />
                            </div>
                        </div>
                    </section>
                    <section className={styles.description}>
                        <label>Description</label>
                        <div>
                            <div>
                                <div>
                                    <textarea value={addDescription_vi} onChange={e => setAddDescription_vi(e.target.value)} placeholder='for Việt Nam' />
                                    <button onClick={() => addDescriptionValue('vi')}>Create</button>                            
                                </div>
                                    <List
                                        values={value.description_list_vi}
                                        onChange={({ oldIndex, newIndex }) =>
                                            setValue(prevState => ({ ...prevState, description_list_vi: arrayMove(value.description_list_vi, oldIndex, newIndex) }))
                                        }
                                        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
                                        renderItem={({ value, props }) => <li {...props}>- {value}<button onClick={()=> removeDescriptionItem('vi', value)}><IoCloseSharp  /></button></li>}
                                    />
                              
                            </div>
                            <div>
                                <div>
                                    <textarea value={addDescription_en} onChange={e => setAddDescription_en(e.target.value)} placeholder='for English'/>
                                    <button onClick={()=> addDescriptionValue('en')}>Create</button>       
                                </div>
                                <List
                                        values={value.description_list_en}
                                        onChange={({ oldIndex, newIndex }) =>
                                            setValue(prevState => ({ ...prevState, description_list_en: arrayMove(value.description_list_en, oldIndex, newIndex) }))
                                        }
                                        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
                                        renderItem={({ value, props }) => <li {...props}>- {value}<button onClick={()=> removeDescriptionItem('en', value)}><IoCloseSharp  /></button></li>}
                                />
                            </div>
                        </div>
                    </section>
                    <section className={styles.color}>
                        <div>
                            <div>
                                <label>Color*</label>
                                <input type='text' value={value.color} onChange={(e) => setValue(prevState => ({ ...prevState, color: e.target.value }))}/>
                            </div>
                        </div>
                        <ul>
                            {
                                colorSuggest && colorSuggest.map((item, index) => (
                                    <li key={`color-${index}`}  onClick={()=> setValue(prevState => ({...prevState, color: item})) }>{item}</li>
                                ))
                            }
                        </ul>
                    </section>
                    <section className={styles.related}>
                        <label>Related Products</label>
                        <ul>
                            {
                                addRelatedValue.url.length === 0 ?
                                addRelatedValue_suggest.map((item,index)=> (
                                    <li key={`suggest-related-item${index}`}
                                        onClick={()=> setAddRelatedValue(prevState => ({...prevState, url: item.url, name: item.color})) }
                                    >   
                                        <div>
                                            <img src={`${PORT}/images/${item.url}/main.jpg`} alt="none" 
                                                onError={(e) => {
                                                    e.target.src = require("../assets/image-slash.png");
                                                }}
                                            />
                                            <div>
                                                <span>{item.name}</span>
                                                <span>/{item.url}</span>
                                            </div>
                                        </div>
                                        <span>{item.color}</span>
                                    </li>
                                ))
                                :
                                addRelatedValue_suggest
                                .filter(item => item.url.toLowerCase().trim().includes(addRelatedValue.url.toLowerCase().trim()))
                                .map((item,index)=> (
                                    <li key={`suggest-related-item${index}`}
                                        onClick={()=> setAddRelatedValue(prevState => ({...prevState, url: item.url, name: item.color})) }
                                    >
                                        <div>
                                            <img src={`${PORT}/images/${item.url}/main.jpg`} alt="none" 
                                                onError={(e) => {
                                                    e.target.src = require("../assets/image-slash.png");
                                                }}
                                            />
                                            <div>
                                                <span>{item.name}</span>
                                                <span>/{item.url}</span>
                                            </div>
                                        </div>
                                        <span>{item.color}</span>
                                    </li>
                                ))
                            }
                        </ul>
                        <div>
                            <div>
                                <input value={addRelatedValue.name} placeholder='Name' onChange={e => setAddRelatedValue(prevState => ({...prevState, name: e.target.value}))} type='text' />
                                
                                <div className={styles.suggestRelated}>
                                    <input value={addRelatedValue.url} placeholder='Url'  onChange={e => setAddRelatedValue(prevState => ({...prevState, url: e.target.value}))} type='text' onFocus={handleRelatedFocus}   />
                                </div>
                                <button onClick={addRelatedItem}>Create</button>
                            </div>
                            <List
                                values={value.related}
                                onChange={({ oldIndex, newIndex }) => 
                                    setValue(prevState =>( {...prevState, related: arrayMove(prevState, oldIndex, newIndex)}))
                                }
                                renderList={({ children, props }) => <ul {...props}>{children}</ul>}
                                renderItem={({ value, props }) => (
                                    <li {...props}>
                                        <div>
                                            <span>
                                                <img src={`${PORT}/images/${value.url}/main.jpg`} alt="none" 
                                                    onError={(e) => {
                                                        e.target.src = require("../assets/image-slash.png");
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <p>{value.name}</p>
                                        <p>/{value.url}</p>
                                        <button onClick={() => removeRelatedItem(value)}><IoCloseSharp size={15} /></button>
                                    </li>
                                )}
                            />
                        </div>
                    </section>
                        <section className={styles.imgFrame}>
                            <div>
                                <div>
                                    <label>Set length Images Extra</label>
                                    <input type='number' value={value.imgExtraLength}  onChange={(e)=>setValue(prevState => ({ ...prevState,imgExtraLength: e.target.value }))}  />
                                </div>
                            </div>
                            {mode === 'edit' && (
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Jpg</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Main</td>
                                            <td>
                                                <img src={`${PORT}/images/${value.url}/main.jpg`} alt="none"  
                                                    onError={(e) => {
                                                        e.target.src = require("../assets/image-slash-2.png");
                                                    }}   
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Hover</td>
                                            <td>
                                                <img src={`${PORT}/images/${value.url}/hover.jpg`} alt="none" 
                                                    onError={(e) => {
                                                        e.target.src = require("../assets/image-slash-2.png");
                                                    }} 
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Size Guide </td>
                                            <td>
                                                <img src={`${PORT}/images/${value.url}/size-guide.jpg`} alt="none" 
                                                    onError={(e) => {
                                                        e.target.src = require("../assets/image-slash-2.png");
                                                    }}   
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Extra </td>
                                            <td>
                                                {
                                                    Array.from({ length: value.imgExtraLength }).map((_, i) => (
                                                            <img key={`extra-${i +1} jpg`} src={`${PORT}/images/${idEdit.url}/extra-${i +1}.jpg`} alt="none" 
                                                                onError={(e) => {
                                                                    e.target.src = require("../assets/image-slash-2.png");
                                                                }} 
                                                            />
                                                        ))
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                  
                        </section>
                    <section className={styles.btnFrame}>
                            <div>
                                {
                                    mode === "edit"? 
                                    <button className={styles.deleteBtn} onClick={()=> setIsModelDelete(true)}>Delete</button>
                                    : null
                                }
                                <button onClick={handleCheck}>Check</button>
                                <button className={isSaveBtn ? styles.activeSave : styles.noneActivesave } onClick={handleSave}>Save</button>
                            </div>
                          
                    </section>
                </div>
            </div>
            {isModelDelete && <ModelDelete />}
            {isModelEdit && <ModelEdit />}
        </>
    )

}


export default EditItem;