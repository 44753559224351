import React, {useEffect, useState} from 'react';
import {Link } from 'react-router-dom';
import axios from '../handle/axiosConfig';
import ProductItem from '../components/ProductItem';
import styles from './Products.module.css';


const Products = () =>{
    const [data,setData] = useState([]);

    useEffect(()=>{
        const fectchData = async ()=>{
            const fetch = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/admin/products/get-all`);
            setData(fetch.data);
        }
        fectchData()
    },[]);

    return (
        <div className={styles.container}>
            <div className={styles.btnList}>
                <div className={styles.addNew}>
                    <Link to={'/products/add'}>Add new product</Link>
                </div>
            </div>
            <div>
                <div>
                    <ProductItem type={'header'} />
                </div>
                <div className={styles.data}>
                    {
                        data.map( item =>  <ProductItem key={item.url}  type={'item'} item={item}  />)
                    }
                </div>
            </div>
        </div>
    )
}

export default Products