import React , {useState, useEffect} from "react";
import styles from './Discount.module.css';
import axios from "../handle/axiosConfig";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { renderNavbar } from "../redux/slices/NavbarSlice";

const Discount = ()=>{
    const dispatch = useDispatch();
    const [addItem_codeName ,setAddItem_codeName ] = useState('');
    const [addItem_number ,setAddItem_number ] = useState('');
    const [addItem_percent ,setAddItem_percent ] = useState('');

    const [listDiscountItem, setListDisCountItem] =  useState([]);
    const [isShowModelDelete, setIsShowModelDelete] = useState(false);
    const [deleteValue, setDeleteValue] = useState();
    const PORT = process.env.REACT_APP_URL_BACKEND;
    const handeAddItem = async () => {
        if (
            addItem_codeName.length !== 0 &&
            addItem_number.length !== 0 &&
            addItem_percent.length !== 0 
        ) {
            try {
                let data = {
                    codeName: addItem_codeName,
                    startNumber: addItem_number,
                    number: addItem_number,
                    percent: addItem_percent,
                };
                const request = await axios.post(`${PORT}/admin/discount/add-item`, data);

                data.updatedAt = new Date();
                setListDisCountItem([data ,...listDiscountItem]);
                dispatch(renderNavbar());
                toast.success("Code has been created .-.");
            } catch (error) {
                toast.error(error.response.data);
            }
        } else {
            if (addItem_codeName.trim().length === 0) {
                toast.error("(codeName)  is null");
            }
            if (addItem_number.trim().length === 0  ) {
                toast.error("(addItem_number) is null");
            }
            if (addItem_percent.trim().length === 0  ) {
                toast.error("(addItem_percent) is null");
            }
        
        }
    };
    useEffect(()=>{
        async function fechtDataList (){
            const getData = await axios.get(`${PORT}/admin/discount/get-all`);
            setListDisCountItem(getData.data);
        }
        fechtDataList();
    },[]);

    const handleDeleteItem = async ()=>{
        try{
            const getData = await axios.post(`${PORT}/admin/discount/delete` , {id: deleteValue} );
            toast.success(getData.data);
            setIsShowModelDelete(false);
            const newData = await listDiscountItem.filter(item => item.codeName !== deleteValue);
            setListDisCountItem(newData);
            dispatch(renderNavbar());
        }catch (error){
            toast.error(error.response.data);
        }
    }
    
    const ModelDelete = () => (
        isShowModelDelete ? (
            <div className={styles.modelDelete}>
                <div>
                    <p>Delete? {deleteValue}</p>
                    <div>
                        <button
                            onClick={()=>{
                                setIsShowModelDelete(false)
                            }}
                        >
                            Cancel
                        </button>   
                        <button
                            onClick={()=>handleDeleteItem()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        ) : null
    );
    
    const DiscountItem = ({value})=>{
        const date = new Date(value.updatedAt);
        const day = date.getDate().toString().padStart(2, '0'); 
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const year = date.getFullYear().toString(); 
        const formattedDateString = `${day}-${month}-${year}`;
        return(
            <div className={`${styles.item} ${value.number === '0' ? styles.noneActiveDiscount : ''}`}>
                <p>{value.codeName}</p>
                <p>{value.startNumber}</p>
                <p>{value.number}</p>
                <p>-{value.percent}%</p>
                <p>{formattedDateString}</p>
                <p><button onClick={()=>{ 
                        setIsShowModelDelete(true)
                        setDeleteValue(value.codeName)
                    }}
                ><RiDeleteBin4Fill/></button></p>
            </div>
        )
    }


    return(
        <div className={styles.layout}>
             <ToastContainer
                position={'top-right'}
                closeOnClick={true}
                autoClose = {5000}
                draggable= {true}
            />
            <ModelDelete />
            <div className={styles.handleFrame}>
                <div className={styles.addItem}>
                    <div >
                        <label>Code Name</label>
                        <input type="text"  value={addItem_codeName} onChange={e=> setAddItem_codeName(e.target.value)} />
                    </div>
                    <div >
                        <label>Number</label>
                        <input type="number"   value={addItem_number} onChange={e=> setAddItem_number(e.target.value)} />
                    </div>
                    <div >
                        <label>% Sale</label>
                        <input type="number"   value={addItem_percent} onChange={e=> setAddItem_percent(e.target.value)} />
                    </div>
                    <button onClick={()=> handeAddItem()}>Create</button>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.listItem_header}>
                        <p>Code</p>
                        <p>Start</p>
                        <p>Now</p>
                        <p>-%</p>
                        <p>Create date</p>
                        <p></p>
                    </div>
                    <div className={styles.listItem_list}>
                        {listDiscountItem.map((item, index) => <DiscountItem key={`${item.codeName}_${index}`} value={item} />)}
                    </div>
                </div>

            </div>
            <div className={styles.detail}>

            </div>
        </div>
    )
};


export default Discount