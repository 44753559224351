import axios from 'axios';

const token = sessionStorage.getItem('token');

axios.defaults.baseURL = 'http://example.com/api'; 
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.response.use(
    response => response, 
    error => {
        if (error.response.status === 403) {
            sessionStorage.removeItem('token');
            window.location.reload();
        }
        return Promise.reject(error);
    }
);


export default axios;