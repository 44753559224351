import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'

import ordersSlice from './slices/OrdersSlice';
import navbarSlice from './slices/NavbarSlice';


const rootReducer = combineReducers({
  ordersSlice,
  navbarSlice
});


const store = configureStore({
  reducer: rootReducer,
});


export default store;
